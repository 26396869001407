const Chat = (props: { fill: string }) => (
	<>
		<path
			d="M10.3809 27.36C10.1441 27.36 9.90088 27.312 9.66728 27.2128C9.00168 26.9312 8.57288 26.2784 8.57288 25.5584V23.8176H6.14728C4.41608 23.8176 3.00488 22.4064 3.00488 20.6752V7.78882C3.00488 6.05762 4.41608 4.64642 6.14728 4.64642H25.8625C27.5937 4.64642 29.0049 6.05762 29.0049 7.78882V20.672C29.0049 22.4064 27.5937 23.8144 25.8625 23.8144H14.7713L11.6193 26.8512C11.2737 27.184 10.8353 27.3568 10.3841 27.3568L10.3809 27.36ZM6.14728 6.24642C5.29608 6.24642 4.60488 6.93762 4.60488 7.78882V20.672C4.60488 21.5232 5.29608 22.2144 6.14728 22.2144H9.37288C9.81448 22.2144 10.1729 22.5728 10.1729 23.0144V25.5552C10.1729 25.664 10.2401 25.7152 10.2945 25.7376C10.3745 25.7728 10.4449 25.76 10.5089 25.6992L13.8945 22.4384C14.0449 22.2944 14.2433 22.2144 14.4481 22.2144H25.8625C26.7137 22.2144 27.4049 21.5232 27.4049 20.672V7.78882C27.4049 6.93762 26.7137 6.24642 25.8625 6.24642H6.14728Z"
			fill={props.fill}
		/>
		<path
			d="M23.4272 10.9536H8.57283C8.13123 10.9536 7.77283 10.5952 7.77283 10.1536C7.77283 9.71198 8.13123 9.35358 8.57283 9.35358H23.4272C23.8688 9.35358 24.2272 9.71198 24.2272 10.1536C24.2272 10.5952 23.8688 10.9536 23.4272 10.9536Z"
			fill={props.fill}
		/>
		<path
			d="M23.4272 14.864H8.57283C8.13123 14.864 7.77283 14.5056 7.77283 14.064C7.77283 13.6224 8.13123 13.264 8.57283 13.264H23.4272C23.8688 13.264 24.2272 13.6224 24.2272 14.064C24.2272 14.5056 23.8688 14.864 23.4272 14.864Z"
			fill={props.fill}
		/>
		<path
			d="M23.4272 18.7776H8.57283C8.13123 18.7776 7.77283 18.4192 7.77283 17.9776C7.77283 17.536 8.13123 17.1776 8.57283 17.1776H23.4272C23.8688 17.1776 24.2272 17.536 24.2272 17.9776C24.2272 18.4192 23.8688 18.7776 23.4272 18.7776Z"
			fill={props.fill}
		/>
	</>
);

export default Chat;
